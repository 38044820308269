'use client';

import React, { useEffect } from 'react';
import { logPageViewToSegment } from '@/utils/segment';
import ROUTES from '../../../constants/routes';
import type { OGP } from '../../../types/page-context';
import { ErrorComponent, ErrorOption } from '../../error';
import LinkInternal from '../../LinkInternal';
import Shell from '../../Shell';
import messages from './messages';

// Convert "bla bla ${link} bla" into valid JSX for inside <ErrorOption>
function optionStringToJSX(text: string, linkText: string, linkTo: string) {
  // eslint-disable-next-line no-template-curly-in-string
  const keyword = '${link}';
  const linkStart = text.indexOf(keyword);
  const linkEnd = linkStart >= 0 ? linkStart + keyword.length : 0;
  return <>
      {linkStart > 0 ? text.slice(0, Math.max(0, linkStart)) : undefined}
      {linkStart >= 0 ? <LinkInternal to={linkTo}>{linkText}</LinkInternal> : undefined}
      {linkEnd < text.length ? text.slice(Math.max(0, linkEnd)) : undefined}
    </>;
}
const ogp: OGP = {
  title: messages.notFound.doc_title,
  description: messages.notFound.doc_description,
  slug: ''
};
const NotFoundPage = () => {
  const {
    title,
    message,
    option_title,
    option_help,
    option_help_link,
    option_home,
    option_home_link
  } = messages.notFound;
  useEffect(() => {
    logPageViewToSegment('/404');
  }, []);
  return <Shell ogp={ogp} data-sentry-element="Shell" data-sentry-component="NotFoundPage" data-sentry-source-file="index.tsx">
      <div className="error-wrap pb-10">
        <ErrorComponent title={title} message={message} optionsTitle={option_title} data-sentry-element="ErrorComponent" data-sentry-source-file="index.tsx">
          <ErrorOption icon="oa-chevron-right" data-sentry-element="ErrorOption" data-sentry-source-file="index.tsx">
            {optionStringToJSX(option_help, option_help_link, ROUTES.help)}
          </ErrorOption>
          <ErrorOption icon="oa-chevron-right" data-sentry-element="ErrorOption" data-sentry-source-file="index.tsx">
            {optionStringToJSX(option_home, option_home_link, ROUTES.home)}
          </ErrorOption>
        </ErrorComponent>
      </div>
    </Shell>;
};
export default NotFoundPage;