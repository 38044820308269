'use client';

import { ErrorComponent, ErrorOption } from '@/components/error';
import LinkInternal from '@/components/LinkInternal';
import Shell from '@/components/Shell';
import messages from '@/components/templates/event-page/messages';
import NotFoundPage from '@/components/templates/not-found-page';
import ROUTES from '@/constants/routes';
import { Providers } from '@/state/wrapWithProviders';
type ErrorProps = {
  error: Error;
  reset: () => void;
};
export default function Error({
  error,
  reset
}: ErrorProps) {
  const {
    title,
    message,
    option_title,
    option_contact,
    option_refresh,
    option_wait
  } = messages.error;
  const {
    pathname
  } = window?.location;
  if (error.message === 'NEXT_NOT_FOUND') {
    return <NotFoundPage />;
  }
  return <Providers data-sentry-element="Providers" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <Shell isFooterTopLineDisabled pathname={pathname} data-sentry-element="Shell" data-sentry-source-file="error.tsx">
        <div className="error-wrap w-50">
          <ErrorComponent title={title} message={message} optionsTitle={option_title} isEventError={false} error={error.message} data-sentry-element="ErrorComponent" data-sentry-source-file="error.tsx">
            <ErrorOption icon="oa-restart" data-sentry-element="ErrorOption" data-sentry-source-file="error.tsx">{option_refresh}</ErrorOption>
            <ErrorOption icon="oa-loader" data-sentry-element="ErrorOption" data-sentry-source-file="error.tsx">{option_wait}</ErrorOption>
            <ErrorOption icon="oa-email" data-sentry-element="ErrorOption" data-sentry-source-file="error.tsx">
              <LinkInternal to={`${ROUTES.contact}/?category=0&subject=${encodeURI(`Error on page ${pathname}`)}&message=${encodeURI(`An error occured on page ${pathname}\n\nDetails: ${error.message}`)}`} data-sentry-element="LinkInternal" data-sentry-source-file="error.tsx">
                {option_contact}
              </LinkInternal>
            </ErrorOption>
          </ErrorComponent>
        </div>
      </Shell>
    </Providers>;
}