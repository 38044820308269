import React, { FC } from 'react';
import classNames from 'classnames';
import { NotificationType } from '@/enums/Notification';
import { LinkType, NotificationObject } from '@/types/Notification';
import Icon from './Icon';
import LinkExternal from './LinkExternal';
import LinkInternal from './LinkInternal';
export type OnAirNotificationProps = NotificationObject & {
  className?: string;
  testId?: string;
  customIconClassName?: string;
};
const OnAirNotification: FC<OnAirNotificationProps> = ({
  text,
  className,
  type = NotificationType.success,
  testId,
  customIconClassName = ''
}) => {
  const renderLink = (link: string, linkText: string, linkType: LinkType) => {
    if (typeof text !== 'string' && text?.isExternal) {
      return <LinkExternal to={link} type={linkType}>
          {linkText}
        </LinkExternal>;
    }
    return <LinkInternal to={link} type={linkType} data-sentry-element="LinkInternal" data-sentry-component="renderLink" data-sentry-source-file="OnAirNotification.tsx">
        {linkText}
      </LinkInternal>;
  };
  return <div className={classNames('notification', {
    'notification--error': type === NotificationType.error,
    'notification--success': type === NotificationType.success,
    'notification--info': type === NotificationType.info,
    'notification--warning': type === NotificationType.warning
  }, className)} data-testid={testId} data-sentry-component="OnAirNotification" data-sentry-source-file="OnAirNotification.tsx">
      <div className="notification__icon-wrapper">
        <Icon icon={classNames({
        'oa-warning': !customIconClassName && (type === NotificationType.error || type === NotificationType.warning),
        'oa-check': !customIconClassName && type === NotificationType.success,
        'oa-information': !customIconClassName && type === NotificationType.info,
        [customIconClassName]: !!customIconClassName
      })} data-sentry-element="Icon" data-sentry-source-file="OnAirNotification.tsx" />
      </div>
      <div className="notification__text-wrapper">
        <p className="body-text uppercase-first-letter">
          {typeof text !== 'string' && text?.preText}
          {typeof text !== 'string' && renderLink(text?.linkTarget, text?.linkText, text?.linkType)}
          {typeof text !== 'string' ? text.postText : text}
        </p>
      </div>
    </div>;
};
export default OnAirNotification;